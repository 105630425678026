
const tourData = [
  {
    id: "delhi",
    title: "Delhi",

  },
  {
    id: "haryana",
    title: "Haryana",
  },
  {
    id: "lucknow",
    title: "lucknow",
  },
];

export default tourData;
