import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import HeaderId from './HeaderId';

const AboutDetails = () => {
  const { id } = useParams();
  return (
    <>
    <HeaderId/>
    <div className="sm:flex items-center max-w-screen-xl mx-auto">
      <div className="sm:w-1/2 p-10">
        <div className="image object-center text-center">
          <img 
            src="https://i.imgur.com/WbQnbas.png" 
            alt="About Our Company" 
            className="w-full h-auto"
          />
        </div>
      </div>
      <div className="sm:w-1/2 p-5">
        <div className="text">
          <span className="text-gray-500 border-b-2 border-indigo-600 uppercase">
            About Us
          </span>
          <h2 className="my-4 font-bold text-3xl sm:text-4xl uppercase">
            Best Furniture Company <span className="text-indigo-600">in {id}</span>
          </h2>
          <p className="text-gray-700">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid, commodi
            doloremque, fugiat illum magni minus nisi nulla numquam obcaecati placeat quia, repellat tempore
            voluptatum.
          </p>
        </div>
      </div>
    </div>
    </>
  );
};

export default AboutDetails;
