import React from 'react';
import TourCard from '../../shared/TourCard';
import { Col } from 'reactstrap';
import tourData from "../../assets/Data/tours"


const FeaturedTourList = () => {
    const handleContactClick = (event) => {
        event.preventDefault();
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
      };
    return (
    <>
      {tourData?.map(tour => (
        <Col key={tour._id}>
          <TourCard tour={tour} onClick={handleContactClick}/>
        </Col>
      ))}
    </>
  );
};

export default FeaturedTourList;