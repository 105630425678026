import React, { useRef, useState } from 'react';
import { Container, Row, Col, Form, ListGroup, Button } from 'reactstrap';
import { useParams } from 'react-router-dom';
import tourData from '../assets/Data/tours';
import HeaderId from './HeaderId';

const TourDetails = () => {
    const { id } = useParams();
    const reviewMsgRef = useRef('');
    const [tourRating, setTourRating] = useState(null);
    const [reviews, setReviews] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    // Fetch data from the tourData array
    const tour = tourData.find(tour => tour.id === id);

    // Destructure properties from the tour object
    const { title } = tour;

    // Calculate average rating function (dummy implementation)
    const calculateAvgRating = (reviews) => {
        const totalRating = reviews.length;
        const avgRating = totalRating > 0 ? reviews.reduce((sum, review) => sum + review.rating, 0) / totalRating : 0;
        return { totalRating, avgRating };
    };

    const { totalRating, avgRating } = calculateAvgRating(reviews);

    // Format date
    const options = { day: "numeric", month: "long", year: "numeric" };

    // Submit request to the server
    const submitHandler = e => {
        e.preventDefault();
        const newReview = { message: reviewMsgRef.current.value, rating: tourRating };
        setReviews([...reviews, newReview]);
        setSubmitted(true);
        reviewMsgRef.current.value = '';
        setTourRating(null);
    };

    return (
        <>
        <HeaderId/>
        <div className="flex flex-col">
            <div className="bg-gray-100 py-8">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl font-bold text-gray-800 mb-2">Welcome {title}</h1>
                    <p className="text-gray-600">Published on April 4, 2023</p>
                </div>
            </div>

            <div className="bg-white py-8">
                <div className="container mx-auto px-4 flex flex-col md:flex-row">
                    <div className="w-full md:w-3/4 px-4">
                        <img
                            src="https://images.unsplash.com/photo-1506157786151-b8491531f063"
                            alt="Blog Featured Image"
                            className="mb-8"
                        />
                        <div className="prose max-w-none">
                            <p>Planning a party in <span className='text-brown font-bold'>{title}</span>? The capital city offers an array of incredible venues and services, ensuring every event is memorable. Whether it’s a birthday, wedding, corporate gathering, or a themed celebration, party hosting services in <span className='text-brown font-bold'>{title}</span> cater to all your needs, providing seamless event management.</p>

                            <p>From selecting stunning venues—like rooftop lounges, farmhouses, and banquet halls—to curating custom menus, these services take care of everything. Enjoy vibrant decor, live entertainment, DJs, and professional photography to capture the moments. Additionally, many hosts offer tailor-made packages to suit your event's budget and theme, ensuring every detail aligns with your vision.</p>

                            <p><span className='text-brown font-bold'>{title}</span> dynamic culture guarantees diverse catering options, from Indian delicacies to international cuisines. Plus, with the city’s network of premium vendors for decorations, sound systems, and lighting, you can create a unique and captivating experience. Whether you want an intimate celebration or a grand affair, <span className='text-brown font-bold'>{title}</span> party hosting services make your special occasion stress-free and unforgettable.</p>

                        </div>
                    </div>

                    <div className="w-full md:w-1/4 px-4">
                        <div className="bg-gray-100 p-4">
                            <h2 className="text-xl font-bold text-gray-800 mb-4">Recent Posts</h2>
                            <ul className="list-none">
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Blog Post 1</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Blog Post 2</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Blog Post 3</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Blog Post 4</a>
                                </li>
                            </ul>
                        </div>

                        <div className="bg-gray-100 p-4 mt-4">
                            <h2 className="text-xl font-bold text-gray-800 mb-4">Categories</h2>
                            <ul className="list-none">
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Category 1</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Category 2</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Category 3</a>
                                </li>
                                <li className="mb-2">
                                    <a href="#" className="text-gray-700 hover:text-gray-900">Category 4</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default TourDetails; 