import React from 'react';
import { Link, useParams } from 'react-router-dom';

const HeaderId = () => {
  const { id } = useParams(); // Get dynamic ID from URL if available

  const handleContactClick = (event) => {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <header className="sticky inset-0 z-50 border-b border-slate-100 bg-white/80 backdrop-blur-lg">
      <nav className="mx-auto flex max-w-6xl gap-8 px-6 transition-all duration-200 ease-in-out lg:px-12 py-4">
        <div className="relative flex items-center">
          <a href="/">
            <img
              src="https://www.svgrepo.com/show/499831/target.svg"
              alt="Logo"
              loading="lazy"
              width="32"
              height="32"
            />
          </a>
        </div>

        <ul className="hidden md:flex items-center justify-center gap-6">
          <li className="pt-1.5 font-dm text-sm font-medium text-slate-700">
            <Link to="/">HOME</Link>
          </li>
          <li className="pt-1.5 font-dm text-sm font-medium text-slate-700">
            <Link to={id ? `/${id}/about-us` : '/about-us'}>ABOUT US</Link>
          </li>
          <li className="pt-1.5 font-dm text-sm font-medium text-slate-700">
            <Link to={id ? `/${id}/dining` : '/dining'}>DINING</Link>
          </li>
        </ul>

        <div className="flex-grow"></div>

        <div className="hidden md:flex items-center justify-center gap-6">
          <a href="#" className="font-dm text-sm font-medium text-slate-700">
            Sign in
          </a>
          <a
            href="#"
            className="rounded-md bg-gradient-to-br from-green-600 to-emerald-400 px-3 py-1.5 
                       font-dm text-sm font-medium text-white shadow-md shadow-green-400/50 
                       transition-transform duration-200 ease-in-out hover:scale-[1.03]"
          >
            Sign up for free
          </a>
        </div>

        <div className="relative flex items-center justify-center md:hidden">
          <button type="button" aria-label="Open menu">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="h-6 w-auto text-slate-900"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </nav>
    </header>
  );
};

export default HeaderId;
