import React from 'react';
import { Link } from "react-router-dom";
import FeaturedTourList from '../FeaturedTourList/FeaturedTourList';

const Footer = () => {
    const handleContactClick = (event) => {
        event.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    return (
        <footer className="bg-gray-800 py-4 text-gray-400">
            <div className="container px-4 mx-auto">
                <div className="-mx-4 flex flex-wrap justify-between">
                    
                    {/* Logo and About Section */}
                    <div className="px-4 my-4 w-full xl:w-1/5">
                        <a href="/" className="block w-56 mb-10" aria-label="Homepage">
                            <svg version="1.1" viewBox="0 0 3368 512" xmlns="http://www.w3.org/2000/svg">
                                {/* SVG Logo */}
                                <g fill="none" fillRule="evenodd">
                                    <g transform="translate(0 -75)">
                                        <rect width="512" height="512" rx="128" fill="#3D5AFE" />
                                        <rect x="149" y="176" width="220" height="220" fill="#fff" />
                                        <circle cx="259" cy="156" r="40" fill="#fff" />
                                        <circle cx="369" cy="286" r="40" fill="#2962FF" />
                                        <text fill="white" fontFamily="Nunito-Bold, Nunito" fontSize="512" fontWeight="bold">
                                            <tspan x="654" y="518">Testing</tspan>
                                        </text>
                                    </g>
                                </g>
                            </svg>
                        </a>
                    </div>

                    {/* Company Links Section */}
                    <nav className="px-4 my-4 w-full sm:w-auto">
                        <h2 className="text-2xl pb-4 mb-4 border-b-4 border-blue-600">Company</h2>
                        <ul className="leading-8">
                            <li><Link to="/about" className="hover:text-blue-400">About Us</Link></li>
                            <li><Link to="/terms" className="hover:text-blue-400">Terms & Conditions</Link></li>
                            <li><Link to="/privacy" className="hover:text-blue-400">Privacy Policy</Link></li>
                            <li><Link to="/contact" className="hover:text-blue-400">Contact Us</Link></li>
                        </ul>
                    </nav>

                    {/* Blog Links Section */}
                    <nav className="px-4 my-4 w-full sm:w-auto">
                        <h2 className="text-2xl pb-4 mb-4 border-b-4 border-blue-600">State</h2>
                        <ul className="leading-8">
                            {/* <li><Link to='/language' className="hover:text-blue-400">Language</Link></li> */}
                            {/* <FeaturedTourList onClick={handleContactClick}/> */}
                            <Link to="/market-area">Market Area</Link>
                        </ul>
                    </nav>

                    {/* Social Media Section */}
                    <div className="px-4 my-4 w-full sm:w-auto xl:w-1/5">
                        <h2 className="text-2xl pb-4 mb-4 border-b-4 border-blue-600">Connect With Us</h2>
                        {/* Uncomment and add actual social media icons if needed */}
                        {/* <div className="flex space-x-2">
                            <SocialIcon href="#" icon="facebook" />
                            <SocialIcon href="#" icon="twitter" />
                            <SocialIcon href="#" icon="instagram" />
                            <SocialIcon href="#" icon="linkedin" />
                        </div> */}
                    </div>

                </div>
            </div>
        </footer>
    );
};

export default Footer;
