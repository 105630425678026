import './App.css';
import Footer from './Components/Footer/Footer';
import Header from './Components/Header/Header';
import Routers from './routes/Routers';

function App() {

  return (
    <>
      {/* <Header /> */}
      <Routers />
      <Footer />
    </>
  );
}

export default App;
