import React from 'react'
import {Routes, Route} from 'react-router-dom'
import Home from '../pages/Home'
import Tour from '../pages/Tour'
import TourDetails from '../pages/TourDetails'
import PageNotFound from '../pages/PageNotFound'
import AboutDetails from '../pages/AboutDetails'
import Aboutus from '../pages/Aboutus'
import About from '../pages/About'
import Dining from '../pages/Dining'
import Dinus from '../pages/Dinus'
import Dine from '../pages/Dine'
import Marketarea from '../pages/Marketarea'


const Routers = () => {


  return (
    <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/tours' element={<Tour/>}/>
        <Route path=':id' element={<TourDetails/>}/>
        {/* <Route path="/about-us" element={<Aboutus />} /> */}
        <Route path="/about-us" element={<About/>} />
        <Route path="/:id/about-us" element={<AboutDetails />} />
        {/* <Route path='/dining-set' element={<Dining/>}/> */}
        <Route path='/:id/dining' element={<Dining/>}/>
        <Route path='/dining' element={<Dine/>}/>
        <Route path='/market-area' element={<Marketarea/>}/>
        <Route path="*" element={<PageNotFound />} />
    </Routes>
  )
}

export default Routers
